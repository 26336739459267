import "./App.css";
import './css/custom.css';
import * as Icon from 'react-bootstrap-icons';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from "react-bootstrap/Image";
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import Offcanvas from 'react-bootstrap/Offcanvas';


function App() {
  return (
    <Container id="top" style={{ height: '100vh' }}>
      <Container className=" d-none d-lg-block">
        <Navbar bg="white" expand="lg">
          <Container>
            <Navbar.Brand href={process.env.REACT_APP_REEBIT_URL}>
              <img
                src={require("./assets/img/iso_logo.png")}
                width="177"
                height="50"
                className="d-inline-block align-top"
                alt="Reebit logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
              <Nav className="pe-2 ">
                <Nav.Link
                  id="newLandingPublicationTopMenuRegistrationLink"
                  href={`${process.env.REACT_APP_REEBIT_URL}register`}
                  className="link-menu"
                >
                  Registrarme
                </Nav.Link>
                <Nav.Link
                  id="newLandingPublicationTopMenuLoginLink"
                  href={`${process.env.REACT_APP_REEBIT_URL}login`}
                  className="link-menu"
                >
                  Iniciar sesión
                </Nav.Link>
              </Nav>
              <Button 
                id="newLandingPublicationTopMenuPublishService"
                href={process.env.REACT_APP_REEBIT_URL} 
                className="publish-service" 
                variant="dark"
              >
                Publicar Servicio
              </Button>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Container className="pt-5 pb-5" >
          <Row>
            <Col md={6} className="d-flex align-items-center">
              <Container>
                <Row>
                  <Col md={12}>
                    <p className="fw-bold reebit-title">
                      Hazte visible para tus clientes
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p
                      style={{
                        fontStyle: 'normal',
                        fontSize: '22px',
                        fontWeight: '500',
                        lineHeight: '150%',
                        letterSpacing: '0em',
                      }}
                    >
                      Que tus clientes te encuentren en la red de clasificados más grande de Chile.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      id="newLandingPublicationMakeVisiblePublishServiceBtn" 
                      className="me-3 reebit-btn-primary" 
                      href={process.env.REACT_APP_REEBIT_URL}
                    >
                      Publicar Servicio
                    </Button>
                    <Button 
                      id="newLandingPublicationMakeVisibleMeetReebitBtn" 
                      variant="outline-dark" 
                      className="know-reebit" 
                      href={process.env.REACT_APP_REEBIT_URL}
                    >
                      Conocer Reebit
                    </Button>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={2} lg={2} className="ps-0">
                    <p 
                      className="mb-0"
                      style={{
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontSize: '26px',
                        lineHeight: '120%',
                        color: '#1877F2',
                        textAlign: 'right',
                      }}
                    >
                      +7000  
                    </p>
                    <p
                      style={{
                        fontStyle: 'normal',
                        fontWeight: '500',
                        fontSize: '15px',
                        lineHeight: '120%',
                        color: '#000000',
                        textAlign: 'right',
                        letterSpacing: '0.001em',
                      }}
                    >
                      Usuarios registrados
                    </p>
                  </Col>
                  <Col md={2} lg={2} className="ps-0">
                    <p 
                      className="mb-0"
                      style={{
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontSize: '26px',
                        lineHeight: '120%',
                        color: '#E83E8C',
                        textAlign: 'right',
                      }}
                    >
                      +2000  
                    </p>
                    <p
                      style={{
                        fontStyle: 'normal',
                        fontWeight: '500',
                        fontSize: '15px',
                        lineHeight: '120%',
                        textAlign: 'right',
                        letterSpacing: '0.001em',
                        color: '#000000',
                      }}
                    >
                      Servicios publicados
                    </p>
                  </Col>
                  <Col md={2} lg={2} className="ps-0">
                    <p 
                      className="mb-0"
                      style={{
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontSize: '26px',
                        lineHeight: '120%',
                        color: '#FD9020',
                        textAlign: 'right',
                      }}
                    >
                      +1000  
                    </p>
                    <p
                      style={{
                        fontStyle: 'normal',
                        fontWeight: '500',
                        fontSize: '15px',
                        lineHeight: '120%',
                        textAlign: 'right',
                        letterSpacing: '0.001em',
                        color: '#000000',
                      }}
                    >
                      Clientes felices
                    </p>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col md={6} className="pe-5">
              <Image
                className="d-inline-block align-bottom"
                src={require("./assets/img/composition.png")}
                roundedCircle
                fluid
              />
            </Col>
          </Row>
        </Container>

        <Container id="why-rebbit" className="pt-3 pb-5">
          <Row>
            <Col md={12} className="d-flex justify-content-center">
              <p
                style={{
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '48px',
                  lineHeight: '120%',
                  textAlign: 'center',
                  color: '#212529',
                }}    
              >
                ¿Por qué Reebit?  
              </p>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md={6}>
              <p
                style={{
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '25px',
                  lineHeight: '150%',
                  textAlign: 'center',
                  color: '#000000',
                }}  
              >
                Dale un salto a tu servicio, publícalo hoy en 3 simples pasos.
              </p>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md={4} className="d-flex align-items-stretch">
              <Card 
                className="text-center"
                style={{
                  border: 'none',
                  backgroundColor: '#fff0'
                }}
                >
                <Card.Img 
                  style={{ width: '204px', alignSelf: 'center' }} 
                  height="204px" 
                  src={require("./assets/img/checklist.png")} 
                />
                <Card.Body>
                  <Card.Title
                    className="d-flex justify-content-center"
                    style={{
                      fontWeight: '500',
                      fontSize: '25px',
                      lineHeight: '150%',
                    }}
                  >
                    1. Registrate
                  </Card.Title>
                  <Card.Text
                    style={{
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '17px',
                      lineHeight: '150%',
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                      letterSpacing: '0.005em',
                      color: '#212529'
                    }}
                  >
                    Crea tu cuenta con tus datos o cuenta de Google.
                  </Card.Text>
                  <Button 
                    id="newLandingPublicationWhyReebitRegisterBtn"
                    className="reebit-btn-primary" 
                    href={`${process.env.REACT_APP_REEBIT_URL}register`}
                  >
                    Registrarse
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="d-flex align-items-stretch">
              <Card 
                className="text-center"
                style={{
                  border: 'none',
                  backgroundColor: '#fff0'
                }}
                >
                <Card.Img 
                  style={{ width: '204px', alignSelf: 'center' }} 
                  height="204px" 
                  src={require("./assets/img/altavoz.png")} 
                />
                <Card.Body>
                  <Card.Title
                    className="d-flex justify-content-center"
                    style={{
                      fontWeight: '500',
                      fontSize: '25px',
                      lineHeight: '150%',
                    }}
                  >
                    2. Publica
                  </Card.Title>
                  <Card.Text
                    style={{
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '17px',
                      lineHeight: '150%',
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                      letterSpacing: '0.005em',
                    }}
                  >
                    Publica tu servicio y suscríbete por $5.990 mensuales y aumenta tus clientes.
                  </Card.Text>
                  <Button 
                    id="newLandingPublicationWhyReebitPublishServiceBtn"
                    className="reebit-btn-primary" 
                    href={process.env.REACT_APP_REEBIT_URL}
                  >
                    Publicar servicio
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="d-flex align-items-stretch">
              <Card 
                className="text-center"
                style={{
                  border: 'none',
                  backgroundColor: '#fff0'
                }}
              >
                <Card.Img 
                  style={{
                    width: '204px', alignSelf: 'center' 
                  }} 
                  height="204px" 
                  src={require("./assets/img/contactos.png")} 
                />
                <Card.Body>
                  <Card.Title
                    className="d-flex justify-content-center"
                    style={{
                      fontWeight: '500',
                      fontSize: '25px',
                      lineHeight: '150%',
                    }}
                  >
                    3. Recibe contactos
                  </Card.Title>
                  <Card.Text
                    style={{
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '17px',
                      lineHeight: '150%',
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                      letterSpacing: '0.005em',
                    }}
                  >
                    Tu servicio estará visible a miles de usuarios en todo Chile
                  </Card.Text>
                  <Button 
                    id="newLandingPublicationWhyReebitVisitReebitBtn"
                    className="reebit-btn-primary" 
                    href={process.env.REACT_APP_REEBIT_URL}
                  >
                    Ir a reebit
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container id="about-us" className="pt-5 pb-5">
          <Row>
            <Col md={7} className="d-flex align-items-center">
              <Container>
                <Row>
                  <Col md={12}>
                    <p 
                      style={{
                        fontStyle: 'normal',
                        fontSize: '48px',
                        fontWeight: '500',
                        lineHeight: '120%',
                      }}
                    >
                      Somos Reebit
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p
                      style={{
                        fontStyle: 'normal',
                        fontSize: '22px',
                        fontWeight: '500',
                        lineHeight: '150%',
                        letterSpacing: '0em',
                      }}
                    >
                      Somos la red de servicios más grande de Chile. 
                      Forma parte de los más de 7.000 clasificados digitales que todos están consultando y 
                      recibe ofertas por tus servicios de clientes cercanos a ti.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="pt-3">
                    <Button 
                      id="newLandingPublicationWeAreReebitSubscribeBtn"
                      variant="dark" 
                      className="me-3 subscribe" 
                      href={process.env.REACT_APP_REEBIT_URL}
                    >
                      Quiero suscribirme
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col md={5} className="pe-5">
              <Image
                className="d-inline-block align-bottom"
                src={require("./assets/img/mano.png")}
                roundedCircle
                fluid
              />
            </Col>
          </Row>
        </Container>

        <Container className="pt-5 pb-5">
          <Row>
            <Col id="success-stories" md={12} className="d-flex justify-content-center">
              <p
                style={{
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '40px',
                  lineHeight: '120%',
                  textAlign: 'center',
                  color: '#212529',
                }}    
              >
                Lo que dicen nuestros usuarios 
              </p>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center pb-3">
            <Col md={12}>
              <p
                style={{
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '25px',
                  lineHeight: '150%',
                  textAlign: 'center',
                  color: '#000000',
                }}  
              >
                Ellos ya aumentaron las consultas de sus servicios con Reebit
              </p>
            </Col>
          </Row>  
          <Row className="d-flex justify-content-center">
            <Col md={3} className="d-flex align-items-stretch">
              <Card 
                className="text-center shadow"
                style={{
                  border: 'none',
                  borderRadius: '24px'
                }}
                >
                <Card.Body>
                  <Card.Title
                    className="d-flex justify-content-center"
                    style={{
                      fontWeight: '600',
                      fontSize: '20px',
                      lineHeight: '30px',
                    }}
                  >
                    ¡Reebit es lo mejor!
                  </Card.Title>
                  <Card.Text>
                    <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                    <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                    <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                    <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                    <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                  </Card.Text>
                  <Card.Text
                    className="px-2"
                    style={{
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '18px',
                      lineHeight: '150%',
                      display: 'flex',
                      alignItems: 'left',
                      textAlign: 'left',
                      letterSpacing: '0.005em',
                      color: '#212529'
                    }}
                  >
                    Publiqué mi servicio de coaching y en pocos días recibí varias consultas y ahora tengo nuevos clientes.
                  </Card.Text>
                </Card.Body>
                <Row className="pb-3 mt-2 d-flex justify-content-center">
                  <Col md={3} lg={3} className="pe-0">
                    <Image
                      className="d-inline-block align-bottom"
                      src={require("./assets/img/feedback_1.png")}
                      roundedCircle
                      fluid
                      style={{
                        height: '42px',
                        width: '42px'
                      }}
                    />
                  </Col>
                  <Col md={6} lg={7} className="ps-0 d-flex align-items-center">
                    <p className="mb-0">Javiera Henríquez</p>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md={3} className="d-flex align-items-stretch">
              <Card 
                className="text-center shadow"
                style={{
                  border: 'none',
                  borderRadius: '24px'
                }}
                >
                <Card.Body>
                  <Card.Title
                    className="d-flex justify-content-center"
                    style={{
                      fontWeight: '600',
                      fontSize: '20px',
                      lineHeight: '30px',
                    }}
                  >
                    Fácil y rápido de usar
                  </Card.Title>
                  <Card.Text>
                    <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                    <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                    <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                    <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                    <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                  </Card.Text>
                  <Card.Text
                    className="px-2"
                    style={{
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '18px',
                      lineHeight: '150%',
                      display: 'flex',
                      alignItems: 'left',
                      textAlign: 'left',
                      letterSpacing: '0.005em',
                      color: '#212529'
                    }}
                  >
                    Publicar es muy fácil y responder las preguntas que me llegan también. La mensajería ayuda mucho.
                  </Card.Text>
                </Card.Body>
                <Row className="pb-3 mt-2 d-flex justify-content-center">
                  <Col md={3} lg={3} className="pe-0">
                    <Image
                      className="d-inline-block align-bottom"
                      src={require("./assets/img/feedback_2.png")}
                      roundedCircle
                      fluid
                      style={{
                        height: '42px',
                        width: '42px'
                      }}
                    />
                  </Col>
                  <Col md={6} lg={7} className="ps-0 d-flex align-items-center">
                    <p className="mb-0">Martín Ruiz</p>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md={3} className="d-flex align-items-stretch">
              <Card 
                className="text-center shadow"
                style={{
                  border: 'none',
                  borderRadius: '24px'
                }}
                >
                <Card.Body>
                  <Card.Title
                    className="d-flex justify-content-center"
                    style={{
                      fontWeight: '600',
                      fontSize: '20px',
                      lineHeight: '30px',
                    }}
                  >
                    Hay de todo
                  </Card.Title>
                  <Card.Text>
                    <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                    <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                    <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                    <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                    <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                  </Card.Text>
                  <Card.Text
                    className="px-2"
                    style={{
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '18px',
                      lineHeight: '150%',
                      display: 'flex',
                      alignItems: 'left',
                      textAlign: 'left',
                      letterSpacing: '0.005em',
                      color: '#212529'
                    }}
                  >
                    Yo publiqué y contraté varios profesionales, y encontré desde un gásfiter hasta una tutora para mi hija.
                  </Card.Text>
                </Card.Body>
                <Row className="pb-3 mt-2 d-flex justify-content-center">
                  <Col md={3} lg={3} className="pe-0">
                    <Image
                      className="d-inline-block align-bottom"
                      src={require("./assets/img/feedback_3.png")}
                      roundedCircle
                      fluid
                      style={{
                        height: '42px',
                        width: '42px'
                      }}
                    />
                  </Col>
                  <Col md={6} lg={7} className="ps-0 d-flex align-items-center">
                    <p className="mb-0">Pablo Luis Pérez</p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>   
        </Container>             

        <Nav
          className="pt-5 d-none d-lg-block"
          style={{
            backgroundColor: "#31353C",
            position: "absolute",
            left: "0",
            width: "100%",
          }}
        >
          <Container>
            <Row style={{ paddingTop: "62px" }}>
              <Col className="pe-5" md={3}>
                <Row>
                  <Navbar.Brand href={process.env.REACT_APP_REEBIT_URL}>
                    <img
                      src={require("./assets/img/logotypes1.png")}
                      width="240px"
                      height="89px"
                      className="d-inline-block align-top"
                      alt="Reebit logo"
                    />
                  </Navbar.Brand>
                </Row>
                <Row style={{ paddingTop: "35px" }}>
                  <Col md={2}>
                    <Nav.Link  href="https://www.instagram.com/reebit.cl/" target="_blank">
                      <Icon.Instagram className="link-rrss-reebit" color="white" fontSize="2rem"/>
                    </Nav.Link>
                  </Col>
                  <Col md={2}>
                    <Nav.Link href="https://www.facebook.com/Reebit.cl/" target="_blank">
                      <Icon.Facebook color="white" className="link-rrss-reebit" fontSize="2rem"/>
                    </Nav.Link>
                  </Col>
                  <Col md={2}>
                    <Nav.Link href="https://www.linkedin.com/company/reebit/" target="_blank">
                      <Icon.Linkedin color="white" className="link-rrss-reebit" fontSize="2rem" />
                    </Nav.Link>
                  </Col>
                </Row>
              </Col>
              <Col className="flex-column" md={3}>
                <Nav.Link
                  className="py-0"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "white",
                  }}
                >
                  Accesos rápidos
                </Nav.Link>
                <Nav.Link
                  href="#top"
                  className="foot-text py-1 link-footer-reebit"
                  eventKey="link-1"
                  style={{ color: "white" }}
                >
                  Inicio
                </Nav.Link>
                <Nav.Link
                  href="#why-rebbit"
                  className="foot-text py-1 link-footer-reebit"
                  eventKey="link-2"
                  style={{ color: "white" }}
                >
                  ¿Por qué Reebit?{" "}
                </Nav.Link>
                <Nav.Link
                  href="#about-us"
                  className="foot-text py-1 link-footer-reebit"
                  style={{ color: "white" }}
                >
                  Somos Reebit
                </Nav.Link>
                <Nav.Link
                  href="#success-stories"
                  className="foot-text py-1 link-footer-reebit"
                  style={{ color: "white" }}
                >
                  Casos de éxito
                </Nav.Link>
              </Col>
              <Col className="flex-column" md={2}>
                <Nav.Link
                  className="py-0 link-footer-reebit"
                  disabled
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "white",
                  }}
                >
                  Conversemos
                </Nav.Link>
                <Nav.Link
                  className="foot-text py-1 link-footer-reebit"
                  eventKey="link-1"
                  style={{ color: "white" }}
                  href="mailto:contacto@reebit.cl"
                >
                  Contacto
                </Nav.Link>
              </Col>
            </Row>
            <Row style={{ marginBottom: "60px", marginTop: "50px" }}>
              <hr style={{ color: "white" }}></hr>
              <Col style={{ marginLeft: "0px", paddingLeft: "0px" }} md={6}>
                <span style={{ color: "white" }} className="foot-text2">
                  Reebit® 2022
                </span>
              </Col>
              <Col md={2}>
                <Nav.Link href={`${process.env.REACT_APP_REEBIT_URL}faqs`} target="_blank" className="p-0">
                  <span style={{ color: "white" }} className="foot-text2 link-footer-reebit">
                    Preguntas frecuentes
                  </span>
                </Nav.Link>
              </Col>
              <Col md={2}>
                <Nav.Link href={`${process.env.REACT_APP_REEBIT_URL}terms`} target="_blank" className="p-0">
                  <span style={{ color: "white" }} className="foot-text2 link-footer-reebit">
                    Términos y condiciones
                  </span>
                </Nav.Link>
              </Col>
              <Col style={{ marginLeft: "0px", paddingLeft: "0px" }} md={2}>
                <Nav.Link href={`${process.env.REACT_APP_REEBIT_URL}policies`} target="_blank" className="p-0">
                  <span
                    style={{ color: "white", textAlign: "right" }}
                    className="foot-text2 link-footer-reebit"
                  >
                    Políticas de privacidad
                  </span>
                </Nav.Link>
              </Col>
            </Row>
          </Container>
        </Nav>
      </Container>

      {/* Versión mobile */}
      <Container className="d-lg-none">
        <Navbar key="lg" bg="white" expand="lg" className="mb-3">
          <Container fluid>
            <Navbar.Brand href={process.env.REACT_APP_REEBIT_URL}>
              <img
                src={require("./assets/img/iso_logo.png")}
                width="177"
                height="50"
                className="d-inline-block align-top"
                alt="Reebit logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-lg`}
              aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link
                    id="newLandingPublicationTopMenuRegistrationLink"
                    href={`${process.env.REACT_APP_REEBIT_URL}register`}
                    className="link-menu"
                    style={{
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#31353C',
                    }}
                  >
                    <Icon.EmojiSmile className="me-3" fontSize="1rem"/>
                    Registrarme
                  </Nav.Link>
                  <Nav.Link
                    id="newLandingPublicationTopMenuLoginLink"
                    href={`${process.env.REACT_APP_REEBIT_URL}login`}
                    className="link-menu"
                    style={{
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#31353C',
                    }}
                  >
                    <Icon.Person className="me-3" fontSize="1rem"/>
                    Iniciar sesión
                  </Nav.Link>
                </Nav>
                <div className="d-grid gap-2 px-2 pt-4">
                  <Button 
                    id="newLandingPublicationTopMenuPublishService"
                    href={process.env.REACT_APP_REEBIT_URL} 
                    className="publish-service" 
                    variant="dark"
                  >
                    Publicar Servicio
                  </Button>
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
        
        {/* Inicio */}
        <Container className="pt-5 pb-5">
          <Row className="d-flex justify-content-center">
            <Col sm={12} md={7} className="pe-5">
              <Image
                className="d-inline-block align-bottom"
                src={require("./assets/img/composition.png")}
                roundedCircle
                fluid
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="d-flex align-items-center">
              <Container>
                <Row>
                  <Col md={12}>
                    <p 
                      className="fw-bold text-center"
                      style={{
                        fontStyle: 'normal',
                        fontSize: '38px',
                        lineHeight: '120%',
                        letterSpacing: '0em',
                      }}    
                    >
                      Hazte visible para tus clientes
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p
                      className="text-center"
                      style={{
                        fontStyle: 'normal',
                        fontSize: '16px',
                        fontWeight: '500',
                        lineHeight: '150%',
                        letterSpacing: '0em',
                      }}
                    >
                    Que tus clientes te encuentren en la red de clasificados más grande de Chile.
                    </p>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col className="pt-2 d-grid gap-2" md={12}>
              <Button 
                id="newLandingPublicationMakeVisiblePublishServiceBtn"
                className="reebit-btn-primary" 
                href={process.env.REACT_APP_REEBIT_URL}
              >
                Publicar Servicio
              </Button>
              <Button 
                id="newLandingPublicationMakeVisibleMeetReebitBtn"
                variant="outline-dark" 
                className="know-reebit" 
                href={process.env.REACT_APP_REEBIT_URL}
              >
                Conocer Reebit
              </Button>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={4}>
              <p 
                className="mb-0"
                style={{
                  fontStyle: 'normal',
                  fontWeight: '700',
                  fontSize: '21px',
                  lineHeight: '120%',
                  color: '#1877F2',
                }}
              >
                +7000  
              </p>
              <p
                style={{
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '15px',
                  lineHeight: '120%',
                  color: '#000000',
                  letterSpacing: '0.001em',
                }}
              >
                Usuarios registrados
              </p>
            </Col>
            <Col xs={4}>
              <p 
                className="mb-0"
                style={{
                  fontStyle: 'normal',
                  fontWeight: '700',
                  fontSize: '26px',
                  lineHeight: '120%',
                  color: '#E83E8C',
                }}
              >
                +2000  
              </p>
              <p
                style={{
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '15px',
                  lineHeight: '120%',
                  letterSpacing: '0.001em',
                  color: '#000000',
                }}
              >
                Servicios publicados
              </p>
            </Col>
            <Col xs={4}>
              <p 
                className="mb-0"
                style={{
                  fontStyle: 'normal',
                  fontWeight: '700',
                  fontSize: '26px',
                  lineHeight: '120%',
                  color: '#FD9020',
                }}
              >
                +1000  
              </p>
              <p
                style={{
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '15px',
                  lineHeight: '120%',
                  letterSpacing: '0.001em',
                  color: '#000000',
                }}
              >
                Clientes felices
              </p>
            </Col>
          </Row>
        </Container>
        {/* Inicio*/}

        {/* Por qué Reebit */}
        <Container id="why-rebbit-mobile" className="pt-3 pb-5">
          <Row>
            <Col md={12} className="d-flex justify-content-center">
              <p
                style={{
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '38px',
                  lineHeight: '120%',
                  textAlign: 'center',
                  color: '#212529',
                }}    
              >
                ¿Por qué Reebit?  
              </p>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md={6}>
              <p
                style={{
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '16px',
                  lineHeight: '150%',
                  textAlign: 'center',
                  color: '#000000',
                }}  
              >
                Dale un salto a tu servicio, publícalo hoy en 3 simples pasos.
              </p>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col sm={12} md={8} className="">
              <Card 
                className="text-center"
                style={{
                  border: 'none',
                  backgroundColor: '#fff0'
                }}
                >
                <Card.Img 
                  style={{ width: '204px', alignSelf: 'center' }} 
                  height="204px" 
                  src={require("./assets/img/checklist.png")} 
                />
                <Card.Body>
                  <Card.Title
                    className="d-flex justify-content-center"
                    style={{
                      fontWeight: '500',
                      fontSize: '25px',
                      lineHeight: '150%',
                    }}
                  >
                    1. Registrate
                  </Card.Title>
                  <Card.Text
                    className="d-flex justify-content-center"
                    style={{
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '16px',
                      lineHeight: '150%',
                      letterSpacing: '0.005em',
                      color: '#212529'
                    }}
                  >
                    Crea tu cuenta con tus datos o cuenta de Google.
                  </Card.Text>
                  <Button 
                    id="newLandingPublicationWhyReebitRegisterBtn"
                    className="reebit-btn-primary" 
                    href={`${process.env.REACT_APP_REEBIT_URL}register`}
                  >
                    Registrarse
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={8} className="">
              <Card 
                className="text-center"
                style={{
                  border: 'none',
                  backgroundColor: '#fff0'
                }}
                >
                <Card.Img 
                  style={{ width: '204px', alignSelf: 'center' }} 
                  height="204px" 
                  src={require("./assets/img/altavoz.png")} 
                />
                <Card.Body>
                  <Card.Title
                    className="d-flex justify-content-center"
                    style={{
                      fontWeight: '500',
                      fontSize: '25px',
                      lineHeight: '150%',
                    }}
                  >
                    2. Publica
                  </Card.Title>
                  <Card.Text
                    style={{
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '16px',
                      lineHeight: '150%',
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                      letterSpacing: '0.005em',
                    }}
                  >
                    Publica tu servicio y suscríbete por $5.990 mensuales y aumenta tus clientes.
                  </Card.Text>
                  <Button 
                    id="newLandingPublicationWhyReebitPublishServiceBtn"
                    className="reebit-btn-primary" 
                    href={process.env.REACT_APP_REEBIT_URL}
                  >
                    Publicar servicio
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={8} className="">
              <Card 
                className="text-center"
                style={{
                  border: 'none',
                  backgroundColor: '#fff0'
                }}
              >
                <Card.Img 
                  style={{
                    width: '204px', alignSelf: 'center' 
                  }} 
                  height="204px" 
                  src={require("./assets/img/contactos.png")} 
                />
                <Card.Body>
                  <Card.Title
                    className="d-flex justify-content-center"
                    style={{
                      fontWeight: '500',
                      fontSize: '25px',
                      lineHeight: '150%',
                    }}
                  >
                    3. Recibe contactos
                  </Card.Title>
                  <Card.Text
                    className="d-flex justify-content-center"
                    style={{
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '16px',
                      lineHeight: '150%',
                      display: 'flex',
                      letterSpacing: '0.005em',
                    }}
                  >
                    Tu servicio estará visible a miles de usuarios en todo Chile
                  </Card.Text>
                  <Button 
                    id="newLandingPublicationWhyReebitVisitReebitBtn"
                    className="reebit-btn-primary" 
                    href={process.env.REACT_APP_REEBIT_URL}
                  >
                    Ir a reebit
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* Por qué Reebit */}

        {/* Somos */}
        <Container id="about-us-mobile" className="pt-5 pb-5">
          <Row>
            <Col md={7} className="d-flex align-items-center">
              <Container>
                <Row>
                  <Col md={12}>
                    <p 
                      className="text-center"
                      style={{
                        fontStyle: 'normal',
                        fontSize: '38px',
                        fontWeight: '600',
                        lineHeight: '120%',
                      }}
                    >
                      Somos Reebit
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p
                      className="text-center"
                      style={{
                        fontStyle: 'normal',
                        fontSize: '16px',
                        fontWeight: '500',
                        lineHeight: '150%',
                        letterSpacing: '0em',
                      }}
                    >
                      Somos la red de servicios más grande de Chile. 
                      Forma parte de los más de 7.000 clasificados digitales que todos están consultando y 
                      recibe ofertas por tus servicios de clientes cercanos a ti.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="pt-3 pb-4 d-grid gap-2">
                    <Button 
                      id="newLandingPublicationWeAreReebitSubscribeBtn"
                      variant="dark" 
                      className="me-3 subscribe" 
                      href={process.env.REACT_APP_REEBIT_URL}
                    >
                      Quiero suscribirme
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col md={5} className="pe-5">
              <Image
                className="d-inline-block align-bottom"
                src={require("./assets/img/mano.png")}
                roundedCircle
                fluid
              />
            </Col>
          </Row>
        </Container>
        {/* Somos */}

        {/* Lo que dicen nuestro usuarios */}
        <Container id="success-stories-mobile" className="pt-5 pb-5">
          <Row>
            <Col md={12} className="d-flex justify-content-center">
              <p
                style={{
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '38px',
                  lineHeight: '120%',
                  textAlign: 'center',
                  color: '#212529',
                }}    
              >
                Lo que dicen nuestros usuarios 
              </p>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center pb-3">
            <Col md={12}>
              <p
                style={{
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '16px',
                  lineHeight: '150%',
                  textAlign: 'center',
                  color: '#000000',
                }}  
              >
                Ellos ya aumentaron las consultas de sus servicios con Reebit
              </p>
            </Col>
          </Row>  
          <Row className="d-flex justify-content-center">
            <Col xs={12} sm={12} md={10}>
              <Carousel variant="dark" controls={false}>
                <Carousel.Item className="pt-2 pb-5 px-3">
                  <Card 
                    className="text-center shadow"
                    style={{
                      border: 'none',
                      borderRadius: '24px'
                    }}
                    >
                    <Card.Body>
                      <Card.Title
                        className="d-flex justify-content-center"
                        style={{
                          fontWeight: '600',
                          fontSize: '20px',
                          lineHeight: '30px',
                        }}
                      >
                        ¡Reebit es lo mejor!
                      </Card.Title>
                      <Card.Text>
                        <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                        <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                        <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                        <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                        <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                      </Card.Text>
                      <Card.Text
                        className="px-2"
                        style={{
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: '15px',
                          lineHeight: '150%',
                          display: 'flex',
                          alignItems: 'left',
                          textAlign: 'left',
                          letterSpacing: '0.005em',
                          color: '#212529'
                        }}
                      >
                        Publiqué mi servicio de coaching y en pocos días recibí varias consultas y ahora tengo nuevos clientes.
                      </Card.Text>
                    </Card.Body>
                    <Row className="pb-3 mt-2 d-flex justify-content-center">
                      <Col xs={3} className="pe-0">
                        <Image
                          className="d-inline-block align-bottom"
                          src={require("./assets/img/feedback_1.png")}
                          roundedCircle
                          fluid
                          style={{
                            height: '42px',
                            width: '42px'
                          }}
                        />
                      </Col>
                      <Col xs={6} className="ps-0 d-flex align-items-center">
                        <p className="mb-0">Javiera Henríquez</p>
                      </Col>
                    </Row>
                  </Card>
                </Carousel.Item>
                <Carousel.Item className="pt-2 pb-5 px-3">
                  <Card 
                    className="text-center shadow"
                    style={{
                      border: 'none',
                      borderRadius: '24px'
                    }}
                    >
                    <Card.Body>
                      <Card.Title
                        className="d-flex justify-content-center"
                        style={{
                          fontWeight: '600',
                          fontSize: '20px',
                          lineHeight: '30px',
                        }}
                      >
                        Fácil y rápido de usar
                      </Card.Title>
                      <Card.Text>
                        <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                        <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                        <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                        <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                        <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                      </Card.Text>
                      <Card.Text
                        className="px-2"
                        style={{
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: '15px',
                          lineHeight: '150%',
                          display: 'flex',
                          alignItems: 'left',
                          textAlign: 'left',
                          letterSpacing: '0.005em',
                          color: '#212529'
                        }}
                      >
                        Publicar es muy fácil y responder las preguntas que me llegan también. La mensajería ayuda mucho.
                      </Card.Text>
                    </Card.Body>
                    <Row className="pb-3 mt-2 d-flex justify-content-center">
                      <Col xs={3} className="pe-0">
                        <Image
                          className="d-inline-block align-bottom"
                          src={require("./assets/img/feedback_2.png")}
                          roundedCircle
                          fluid
                          style={{
                            height: '42px',
                            width: '42px'
                          }}
                        />
                      </Col>
                      <Col xs={6} className="ps-0 d-flex align-items-center">
                        <p className="mb-0">Martín Ruiz</p>
                      </Col>
                    </Row>
                  </Card>
                </Carousel.Item>
                <Carousel.Item className="pt-2 pb-5 px-3">
                  <Card 
                    className="text-center shadow"
                    style={{
                      border: 'none',
                      borderRadius: '24px'
                    }}
                    >
                    <Card.Body>
                      <Card.Title
                        className="d-flex justify-content-center"
                        style={{
                          fontWeight: '600',
                          fontSize: '20px',
                          lineHeight: '30px',
                        }}
                      >
                        Hay de todo
                      </Card.Title>
                      <Card.Text>
                        <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                        <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                        <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                        <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                        <Icon.StarFill className="me-1" color="#FFB700" fontSize="1rem"/>
                      </Card.Text>
                      <Card.Text
                        className="px-2"
                        style={{
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: '15px',
                          lineHeight: '150%',
                          display: 'flex',
                          alignItems: 'left',
                          textAlign: 'left',
                          letterSpacing: '0.005em',
                          color: '#212529'
                        }}
                      >
                        Yo publiqué y contraté varios profesionales, y encontré desde un gásfiter hasta una tutora para mi hija.
                      </Card.Text>
                    </Card.Body>
                    <Row className="pb-3 mt-2 d-flex justify-content-center">
                      <Col xs={3} className="pe-0">
                        <Image
                          className="d-inline-block align-bottom"
                          src={require("./assets/img/feedback_3.png")}
                          roundedCircle
                          fluid
                          style={{
                            height: '42px',
                            width: '42px'
                          }}
                        />
                      </Col>
                      <Col xs={6} className="ps-0 d-flex align-items-center">
                        <p className="mb-0">Pablo Luis Pérez</p>
                      </Col>
                    </Row>
                  </Card>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Container>
        {/* Lo que dicen nuestro usuarios */}

        {/* Footer */}
        <Nav
          className="pt-5"
          style={{
            backgroundColor: "#31353C",
            position: "absolute",
            left: "0",
            width: "100%",
          }}
        >
          <Container>
            <Row className="text-center">
              <Col className="flex-column pb-4" sm={3} md={6}>
                <Nav.Link
                  className="py-0"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    color: "white",
                  }}
                >
                  Accesos rápidos
                </Nav.Link>
                <Nav.Link
                  href="#top"
                  className="foot-text py-1"
                  eventKey="link-1"
                  style={{ color: "white" }}
                >
                  Inicio
                </Nav.Link>
                <Nav.Link
                  href="#why-rebbit-mobile"
                  className="foot-text py-1"
                  eventKey="link-2"
                  style={{ color: "white" }}
                >
                  ¿Por qué Reebit?{" "}
                </Nav.Link>
                <Nav.Link
                  href="#about-us-mobile"
                  className="foot-text py-1"
                  style={{ color: "white" }}
                >
                  Somos Reebit
                </Nav.Link>
                <Nav.Link
                  href="#success-stories-mobile"
                  className="foot-text py-1"
                  style={{ color: "white" }}
                >
                  Casos de éxito
                </Nav.Link>
              </Col>
              <Col className="flex-column" sm={3} md={6}>
                <Nav.Link
                  className=""
                  disabled
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    color: "white",
                  }}
                >
                  Conversemos
                </Nav.Link>
                <Nav.Link
                  className="foot-text pb-3"
                  eventKey="link-1"
                  style={{ color: "white" }}
                  href="mailto:contacto@reebit.cl"
                >
                  Contacto
                </Nav.Link>

                <Nav.Link
                  className="foot-text"
                  eventKey="link-1"
                  style={{ color: "white" }}
                  target="_blank"
                  href={`${process.env.REACT_APP_REEBIT_URL}faqs`}
                >
                  Preguntas frecuentes
                </Nav.Link>
                <Nav.Link
                  className="foot-text"
                  eventKey="link-1"
                  style={{ color: "white" }}
                  target="_blank"
                  href={`${process.env.REACT_APP_REEBIT_URL}terms`}
                >
                  Términos y condiciones
                </Nav.Link>
                <Nav.Link
                  className="foot-text"
                  eventKey="link-1"
                  style={{ color: "white" }}
                  target="_blank"
                  href={`${process.env.REACT_APP_REEBIT_URL}policies`}
                >
                  Políticas de privacidad
                </Nav.Link>
              </Col>
            </Row>
            <Row className="text-center py-4 d-flex justify-content-center">
              <Col xs={2} className="d-flex justify-content-center">
                <Nav.Link href="https://www.instagram.com/reebit.cl/" target="_blank">
                  <Icon.Instagram color="white" fontSize="2rem"/>
                </Nav.Link>
              </Col>
              <Col xs={2} className="d-flex justify-content-center">
                <Nav.Link href="https://www.facebook.com/Reebit.cl/" target="_blank">
                  <Icon.Facebook color="white" fontSize="2rem"/>
                </Nav.Link>
              </Col>
              <Col xs={2} className="d-flex justify-content-center">
                <Nav.Link href="https://www.linkedin.com/company/reebit/" target="_blank">
                  <Icon.Linkedin color="white" fontSize="2rem" />
                </Nav.Link>
              </Col>
            </Row>
            <Row className="text-center py-4">
              <Navbar.Brand href={process.env.REACT_APP_REEBIT_URL}>
                <img
                  src={require("./assets/img/logotypes1.png")}
                  width="240px"
                  height="89px"
                  className="d-inline-block align-top"
                  alt="Reebit logo"
                />
              </Navbar.Brand>
            </Row>
            <Row className="mb-3 text-center">
              <hr style={{ color: "white" }}></hr>
              <Col style={{ marginLeft: "0px", paddingLeft: "0px" }} md={6}>
                <span style={{ color: "white" }} className="foot-text2">
                  Reebit® 2022
                </span>
              </Col>
            </Row>
          </Container>
        </Nav>
      </Container>
      {/* Versión mobile */} 
    </Container>
  );
}

export default App;
